import { UserProvider, useUser } from '@auth0/nextjs-auth0/client';
import type { AppProps } from 'next/app';
import { Inter } from 'next/font/google';
import { useRouter } from 'next/router';
import Script from 'next/script';
import postHog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';

import { ServiceProvider } from 'ollie-common/platform/providers/service.provider';
import { LoadingLayout } from '@/src/components/layouts/loading-layout/loading-layout.component';
import { FeatureChangesModal } from '@/src/components/modals/feature-changes-modal/feature-changes-modal.component';
import { useModal } from '@/src/services/modal';
import { useAnalytics } from 'ollie-common/hooks/analytics';
import { ToasterContainer } from 'ollie-common/hooks/toaster';
import { ModalProvider } from 'ollie-common/providers/modal.provider';
import { Modal } from '@/src/shared/ui-kit/components/modal';
import { NextEventSource } from '@/src/utils/evenst-source.utils';

import '@/styles/globals.scss';
import '@/styles/light.theme.scss';

const inter = Inter({ subsets: ['latin'] });

if (typeof window !== 'undefined') {
  postHog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || '', {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug();
    },
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  });
}

const AppWithAnalytics: React.FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();
  const { user, isLoading } = useUser();

  const { capturePageView } = useAnalytics();
  // const { userData$, setUserData } = useInjectable<IUserService>(
  //   UserServiceContainerType,
  // );

  const { open, close } = useModal((props) => (
    <FeatureChangesModal
      onGotIt={() => {
        // localStorage.setItem(APP_STORAGE_KEY, currentAppVersion);
        close();
      }}
    />
  ));

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      new PerformanceObserver((entryList) => {
        for (const entry of entryList.getEntries()) {
          // console.log('Layout shift:', entry);
        }
      }).observe({ type: 'layout-shift', buffered: true });
    }
  }, []);

  // useEffect(() => {
  //   if (user && user.sub) {
  //     setUserData({ userId: user.sub });
  //   }
  // }, [user, setUserData]);

  useEffect(() => {
    // let userData: IUserData | null = null;

    const handleRouteChange = (url: string) => {
      capturePageView(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, capturePageView]);

  return (
    <ServiceProvider
      value={{
        getUserHeaders: () => {
          const headers = new Map();

          headers.set('user-agent', navigator.userAgent);
          headers.set('platform', navigator.platform);
          headers.set('language', navigator.language);
          headers.set('screen-width', screen.width.toString());
          headers.set('screen-height', screen.height.toString());
          headers.set('screen-color-depth', screen.colorDepth.toString());
          headers.set('viewport-width', window.innerWidth.toString());
          headers.set('viewport-height', window.innerHeight.toString());
          headers.set('device-pixel-ratio', window.devicePixelRatio.toString());

          // if (user) {
          //   // headers.append('user-id', userData.userId || '');
          //   headers.append('user-fingerprint-id', user.fingerPrintId || '');
          // }

          return Object.fromEntries(headers.entries());
        },
        eventSourceConstructor: NextEventSource,
      }}
    >
      <PostHogProvider client={postHog}>
        <ModalProvider
          render={({
            component: Component,
            id,
            props: {
              className,
              bodyClassName,
              backdropClassName,
              contentClassName,
              ...props
            },
          }) => {
            return (
              <Modal.Container
                key={id}
                className={className}
                bodyClassName={bodyClassName}
                // contentClassName={contentClassName}
              >
                <Component {...props} />
              </Modal.Container>
            );
          }}
        >
          <Component {...pageProps} />
        </ModalProvider>
        {isLoading ? <LoadingLayout /> : null}
        <ToasterContainer />
      </PostHogProvider>
    </ServiceProvider>
  );
};

export default function App(props: AppProps) {
  return (
    <>
      <style jsx global>{`
        html,
        button {
          font-family: ${inter.style.fontFamily};
        }
      `}</style>

      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${
            process.env.NEXT_PUBLIC_GTM_AUTH || 'OsiubkGjmpooUwtrU9biBA'
          }&gtm_preview=${
            process.env.NEXT_PUBLIC_GTM_ENV_NUMBER || 'env-29'
          }&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TMX9RQ78');`,
        }}
      />

      <UserProvider>
        <AppWithAnalytics {...props} />
      </UserProvider>

      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${
          process.env.NEXT_PUBLIC_GA_ID || 'G-0J84MMS001'
        }`}
      />

      <Script
        id="google-tag-manager"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${
              process.env.NEXT_PUBLIC_GA_ID || 'G-0J84MMS001'
            }', {
              page_path: window.location.pathname,
            });

            window.gtag = gtag;
          `,
        }}
      />
    </>
  );
}
