import { FunctionComponent, useContext, useRef } from 'react';
import {
  Confirm,
  ConfirmModalProps,
  Slide,
} from '@/src/shared/ui-kit/components/modal';
import {
  ModalContext,
  BaseModalProps,
} from 'ollie-common/providers/modal.provider';

export interface UseModalResult<T> {
  close: () => void;
  open: (props: T) => void;
}

export function useModal<T>(
  component: FunctionComponent<BaseModalProps & T>,
): UseModalResult<T & BaseModalProps> {
  const { openModal } = useContext(ModalContext);
  const closeRef = useRef<(() => void) | null>(null);

  return {
    open: (props: T) => {
      const { onClose } = openModal(component, props);
      closeRef.current = onClose;
    },
    close: () => {
      if (closeRef.current) {
        closeRef.current();
      } else {
        throw new Error('Tried to close a modal which has not been opened');
      }
    },
  };
}

export function useConfirmModal(): UseModalResult<ConfirmModalProps> {
  return useModal(Confirm);
}

export function useSlideModal<T>(
  Component: FunctionComponent<T & BaseModalProps>,
): UseModalResult<T & BaseModalProps> {
  const { openModal } = useContext(ModalContext);
  const closeRef = useRef<(() => void) | null>(null);
  return {
    open: (props: T = {} as T) => {
      const { onClose } = openModal(
        () => (
          <Slide onClose={onClose}>
            <Component onClose={onClose} {...props}></Component>
          </Slide>
        ),
        {
          className: 'fullscreenContainerOverlay',
          bodyClassName: 'fullscreenContainer',
          backdropClassName: 'backdropRecipientModal',
          ...props,
        },
      );
      closeRef.current = onClose;
    },
    close: () => {
      if (closeRef.current) {
        closeRef.current();
      } else {
        throw new Error('Tried to close a modal which has not been opened');
      }
    },
  };
}
